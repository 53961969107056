<template>
  <div>
    <b-row>
      <b-col md="9">
        <h2 class="pl-1">Video Details</h2>
      </b-col>
      <b-col>
        <div class="btn-wrapper">
          <b-button
            v-if="MediaIDs.length"
            type="button"
            :variant="'outline-secondary'"
            class="mr-1 capture-btn"
            @click.prevent="saveToLibrarySelected()"
          >
            Save to Activity Media Library
          </b-button>
          <div
            v-if="MediaIDs.length"
            class="media-counter"
          >
            {{ MediaIDs.length }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="item in Frames"
        :key="item.index"
        class="p-1"
      >
        <div
          class="boarder-content"
          :class="{'selected-img' : (MediaIDs.indexOf(item.index) !== -1)}"
        >
          <div
            class="video-container cursor-pointer"
            @click="selectedVideo(item)"
          >
            <div class="video-content">
              <img width="100%" :src="'https://api.nimbushd.com/Imaging/Thumbnail.ashx?id=' + item.id + '&p=' + item.index"/>
            </div>
            <div>{{ item.index | minutesFormat }}</div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="btn-wrapper">
          <b-button
            v-if="MediaIDs.length"
            type="button"
            :variant="'outline-secondary'"
            class="mr-1 capture-btn"
            @click.prevent="saveToLibrarySelected()"
          >
            Save to Activity Media Library
          </b-button>
          <div
            v-if="MediaIDs.length"
            class="media-counter"
          >
            {{ MediaIDs.length }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="loading"
      class="d-flex justify-content-center"
    >
      <b-spinner
        style="width: 5rem; height: 5rem;"
        variant="success"
        label="Spinning"
      />
    </b-row>
  </div>
</template>

<script>

import {BButton, BCol, BRow, BSpinner} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import APIService from "@core/utils/APIService";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner
  },

  filters: {
    minutesFormat(val) {
      const minutes = Math.floor(val / 1000 / 60);
      const seconds = Math.floor(val / 1000 ) - minutes * 60;
      const minutesTxt = minutes === 1 ? " minute " : " minutes "
      const secondsTxt = minutes === 1 ? " second " : " seconds "
      return minutes + minutesTxt + seconds + secondsTxt;
    },
  },

  data() {
    return {
      VideoID: null,
      videoTime: null,
      Frames: [],
      SubFrames: [],
      MediaIDs: [],
      ActivityID: null,
      threshold: 0,
      loading: false,
    }
  },

  mounted() {
    this.VideoID = this.$route.params.videoID;
    this.ActivityID = this.$route.params.actionID;
    this.videoTime = this.$route.params.duration;
    if (this.VideoID) {
      const countFrames = Math.round(this.videoTime / 1000);
      if (countFrames && countFrames > 0)this.createItems(countFrames);
      window.addEventListener('scroll', this.checkPosition)
      window.addEventListener('resize', this.checkPosition)
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.checkPosition)
    window.removeEventListener('resize', this.checkPosition)
  },

  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Frame Image Saved',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },

    createItems(count) {
      for (let i = 0; i <= count; i++) {
        const item = {
          id: this.VideoID,
          index: i * 1000
        }
        this.SubFrames.push(item)
      }
      const winCount = this.getWinCount();
      this.Frames = this.SubFrames.splice(0, winCount);
    },

    selectedVideo(item) {
      const index = this.MediaIDs.indexOf(item.index);
      if (index === -1) {
        this.MediaIDs.push(item.index);
      } else {
        this.MediaIDs.splice(index, 1);
      }
    },

    saveToLibrarySelected() {
      this.MediaIDs.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
      if (this.MediaIDs.length) {
        this.saveToLibrary(this.MediaIDs[0])
      }
    },

    saveToLibrary(selectedFrame) {
      const postData = {
        "ActivityID": this.ActivityID,
        "VideoID": this.VideoID,
        "Ms": selectedFrame
      }
      apiService
          .post("file/activity/save-media-to-library", postData)
          .then(res => {
            if (res) {
              this.MediaIDs.shift();
              this.showToast('success', 'top-center', 4000, 'The frame image has been saved to the Activity Media Library.');
              this.saveToLibrarySelected();
            }
          })
    },

    getWinCount() {
      const screenHeight = window.innerHeight
      const screenWidth = window.innerWidth

      const winColumns = Math.round(screenHeight / 300);
      const winRows = Math.round(screenWidth / 200);

      return (winColumns * winRows) + winRows;
    },

    checkPosition() {
      const height = document.body.offsetHeight
      const screenHeight = window.innerHeight
      const scrolled = window.scrollY

      const winCount = this.getWinCount();

      const position = scrolled

      if (this.threshold && position >= this.threshold) {
        const subArr = this.SubFrames.splice(0, winCount);
        this.Frames = this.Frames.concat(subArr);
        this.threshold = this.threshold + height;
        this.disableScroll();
        setTimeout(() => {
          this.enableScroll();
        }, winCount * 100)
      }

      if (!this.threshold && this.threshold < position) {
        this.threshold = this.threshold + height - screenHeight / 2;
      }
    },

    disableScroll() {
      this.loading = true;
      window.removeEventListener('scroll', this.checkPosition)
    },

    enableScroll() {
      this.loading = false;
      window.addEventListener('scroll', this.checkPosition);
      window.scrollTo(0, window.scrollY - 100);
    },
  }
}
</script>

<style scoped>
.video-container img {
  width: inherit;
  height: inherit;
}
.video-container {
  display: inline-block;
  background-color: grey;
  height: 144px;
  width: 256px;
  position: relative;
}
.video-container:hover > .video-content {
  scale: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transition-duration: 0.5s;
}
.video-content {
  pointer-events: none;
  display: inline-block;
  background-color: grey;
  height: 144px;
  width: 256px;
}
.selected-img {
  border: 3px solid rgba(85, 255, 85, 1);
  width: fit-content;
}
.media-counter {
  background-color: red;
  color: white;
  padding-top: 0.1rem;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  top: -0.5rem;
  right: 0.5rem;
  margin-left: 0.6rem;
  border-radius: 50%;
  font-size: 1rem;
  text-align: center;
}
.btn-wrapper {
  position: relative;
  width: fit-content;
}
</style>